// require('offline-plugin/runtime').install();
localStorage.debug = process.env.config.debug ? "dittypal" : "-*";

import Vue from "vue";
import App from "./components/App.vue";
import Store from "./Store";
import VeeValidate from "vee-validate";

require("./style/complete.scss");

Vue.use(VeeValidate);

new Vue({
  el: "#app",
  render: h => h(App)
});


document.body.addEventListener("keydown", e => {
  if (e.which === 32) {
    e.preventDefault();
  }
});

updateIsMobileFlag();
window.addEventListener("resize", updateIsMobileFlag);


function updateIsMobileFlag() {
  const isDesktop = window.matchMedia("(min-width: 768px)").matches;
  Store.commit("update_is_mobile", !isDesktop);
}