"use strict";

import {CognitoUserPool} from 'amazon-cognito-identity-js';

const config = process.env.config;

export default function getCogintoUserPool() {
  return new CognitoUserPool({
    UserPoolId: config.aws.cognito.userPoolId,
    ClientId: config.aws.clientId
  });
};