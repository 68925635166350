<template>
  <div>
    <div class="full-loading-wrapper" v-show="status==='loading_tracks' && availableTracks.length===0">
      <loading>
      </loading>
    </div>
    <track-list v-show="status==='loading_tracks_complete' || availableTracks.length > 0" :activeTrack="activeTrack"
                :tracks="availableTracks"></track-list>
  </div>
</template>

<script>
  import TrackList from "../tracklist/Track-List.vue";
  import AWS, {s3, lambda} from "../../services/aws/aws-client";
  import Loading from "../loading/Loading.vue";
  import Bus from "../../Bus";
  import debug from "../../debugger";

  const config = process.env.config;

  export default {
    name: "Home",
    data() {
      return {
        status: ""
      };
    },
    computed: {
      availableTracks() {
        return this.$store.getters.availableTracks;
      },
      activeTrack() {
        return this.$store.getters.activeTrack;
      },
    },
    components: {
      Loading,
      TrackList
    },
    mounted() {
      let self = this;
      this.getTracks();

      Bus.$on("creds-refreshed", function () {
        debug("creds refreshed");
        self.getTracks(); //update urls
      });
    },

    methods: {
      getTracks() {
        let self = this;
        let store = self.$store;

        let params = {
          FunctionName: 'getTracksForUser'
        };

        self.status = "loading_tracks";
        lambda.invoke(params, function (error, response) {
          if (error) {
            self.status = "loading_tracks_failed";
            console.error("error getting tracks", error);
          } else {

            const tracks = JSON.parse(JSON.parse(response.Payload).body);

            tracks.map(t => {
              t.mp3 = s3.getSignedUrl("getObject", {
                Bucket: config.aws.trackBucketName,
                Key: `cognito/${AWS.config.credentials.identityId}/${t.title}`,
                Expires: 86400
              });
            });

            store.dispatch("initializeTracks", tracks);
            self.status = "loading_tracks_complete";
            return tracks;
          }
        });
      },
    }
  };
</script>

<style lang="scss">

</style>
