<template>
  <div class="wrapper">
    <!--<h1>-->
    <!--upload-->
    <!--</h1>-->
    <div id="dropzone" class="">
      <div class="dropzone-header clickable">
        <div class="upload-prompt">
          <div class="mobile-show">Upload mp3 track</div>
          <div class="mobile-hide">Drop .mp3 files here to upload</div>
        </div>
      </div>
      <div v-for="queuedTrack in queuedTracks">
        <dropzone-preview :dropzone="dropzone" :queuedTrack="queuedTrack"></dropzone-preview>
      </div>
      <!--<div v-show="queuedTracks.length> 0" v-if="dropzone" class="dropzone-controls">-->
      <!--<button @click="removeAll">-->
      <!--cancel all pending-->
      <!--</button>-->

      <!--<button @click="uploadAll">-->
      <!--upload all-->
      <!--</button>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import getPresignedUrl from "../../services/get-signed-s3-url";
//  import getDropzonePreviewTemplate from "./get-dropzone-template";
import saveTrackToDatabase from "../../services/save-track-to-database";
import DropzonePreview from "./dropzone-preview";

let self;
export default {
  name: "UploadTrack",
  components: { DropzonePreview },
  computed: {
    uploadProgress() {
      return this.$store.getters["upload/uploadProgress"];
    },
    queuedTracks() {
      return this.$store.getters["upload/queuedTracks"];
    },
    dropzone() {
      return this.$store.getters["upload/dropzone"];
    }
  },
  methods: {
    removeAll() {
      this.dropzone.removeAllFiles();
    },
    uploadAll() {
      console.log(this.dropzone.getQueuedFiles());
      this.dropzone.processQueue();
    }
  },
  mounted() {
    createDropZone.apply(this);

    function createDropZone() {
      self = this;
      let dropzoneOptions = {
        dictDefaultMessage: "Drop files here to upload",
        acceptedFiles: "audio/mpeg,audio/mp3",
        //          parallelUploads: 4,
        clickable: ".clickable",
        url: "/",
        method: "put",
        timeout: 300000,
        //          autoProcessQueue: false,

        accept(file, done) {
          if (file.isDuplicate) {
            return;
          }
          file.uploadUrl = getPresignedUrl(file);
          self.$store.commit("upload/update_queued_track", file);
          done();
        },
        sending: function(file, xhr) {
          const _send = xhr.send;
          xhr.send = function() {
            _send.call(xhr, file);
          };
          self.$store.commit("upload/update_queued_track", file);
        },
        processing: function(file) {
          this.options.url = file.uploadUrl;
          self.$store.commit("upload/update_queued_track", file);
        },
        uploadprogress(file) {
          self.$store.commit("upload/update_queued_track", file);
        },
        success(file) {
          self.$store.commit("upload/update_queued_track", file);
        },
        error(file, err, xhr) {
          console.error(err);
        },
        addedfile(file) {
          if (!Dropzone.isValidFile(file, this.options.acceptedFiles)) {
            console.warn("Invalid file type");
            return;
          }
          if (isDuplicate.call(this, file)) {
            file.isDuplicate = true;
            this.removeFile(file);
            console.warn("Not adding duplicate file", file.name);
            return;
          }
          self.$store.commit("upload/add_track_to_upload_queue", file);

          function isDuplicate(file) {
            if (this.files.length) {
              let _i, _len;
              for (
                _i = 0, _len = this.files.length;
                _i < _len - 1;
                _i++ // -1 to exclude current file
              ) {
                if (
                  this.files[_i].name === file.name &&
                  this.files[_i].size === file.size &&
                  this.files[_i].lastModifiedDate.toString() ===
                    file.lastModifiedDate.toString()
                ) {
                  return true;
                }
              }
            }
            return false;
          }
        },
        canceled() {},
        complete(file) {
          if (file.status === "canceled" || !file.accepted) {
            console.log(`upload for ${file.name} canceled or not accepted`);
          } else {
            saveTrackToDatabase(file);
          }
        },
        removedfile(file) {
          console.log("removed", file);
          if (file.isDuplicate) {
            return; //duplicate was never added to store queue
          }
          file.removed = true;
          self.$store.commit("upload/remove_file_from_queue", file.name);
        }
      };
      let dropzone = new Dropzone("div#dropzone", dropzoneOptions);
      self.$store.commit("upload/update_dropzone", dropzone);
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
}

.upload-info-bar {
  display: flex;
  flex: 1;
  margin: 10px 0;
}

#dropzone {
  display: flex;
  min-height: 100px;
  border: 2px solid #679289;
  cursor: pointer;
  flex-direction: column;

  .dropzone-header {
    display: flex;
    padding: 20px;
  }

  .dropzone-controls {
    display: flex;
    flex: 1;
  }

  .upload-prompt {
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }
}
</style>