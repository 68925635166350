<template>
  <div class="app-wrapper">
    <div class="full-loading-wrapper" v-if="!loaded">
      <loading></loading>
    </div>
    <div class="loaded-wrapper" v-if="loaded">
      <div v-bind:class="{'sidebar-present player-present':['Login', 'Register'].indexOf($route.name) === -1}"
           class="sidebar-content-wrapper">
        <div class="sidebar-wrapper" v-show="isAuthenticated">
          <sidebar></sidebar>
        </div>
        <transition name="fade" mode="out-in">
          <router-view class="content"
                       v-bind:class="{'sidebar-present player-present':['Login', 'Register'].indexOf($route.name) === -1}"></router-view>
        </transition>
      </div>
      <div v-if="isAuthenticated">
        <player :track="activeTrack"/>
      </div>
    </div>
  </div>

</template>


<script>
  import Player from "./player/Player.vue";
  import TrackList from "./tracklist/Track-List";
  import musicApi from "../services/music-api";
  import Store from "../Store";
  import audioManager from "./player/audio-manager";
  import Bus from "../Bus";
  import Router from "../router";
  import Auth from "../services/auth/";
  import Sidebar from "./sidebar/Sidebar.vue";
  import {initCredentials} from "../services/aws/aws-client";
  import Loading from "./loading/Loading.vue";

  let self;
  export default {
    name: "app",
    created() {
      const self = this;
      initCredentials()
        .catch(function (err) {
          Auth.logOut();
          let currentRoute = self.$router.history.current.name;
          if (currentRoute !== "Register" && currentRoute !== "Login") {
            self.$router.push("/login");
          }
        })
        .then(function () {
          self.loaded = true;
        });
    },
    data() {
      return {
        loaded: false
      };
    },
    mounted() {
      self = this;
      Bus.$on("track-end", function () {
        self.playNext();
      });
    },
    computed: {
      availableTracks() {
        return this.$store.getters.availableTracks;
      },
      activeTrack() {
        return this.$store.getters.activeTrack;
      },
      isAuthenticated() {
        return this.$store.getters["auth/isAuthenticated"];
      }
    },
    methods: {
      playNext() {
        const nextTrack = this.$store.getters.nextTrack;
        if (nextTrack) {
          audioManager.play(nextTrack);
        }
      }
    },
    components: {
      Loading,
      Sidebar,
      TrackList,
      Player
    },
    store: Store,
    router: Router
  };
</script>

<style scoped lang="scss">
  @import "~style/variables";
  @import "./style/responsive";

  .app-wrapper {
    height: 100%;
    display: flex;
    flex: 1;
  }

  .loaded-wrapper {
    flex: 1;
  }

  .sidebar-wrapper {
    height: calc(100% - 90px);
    width: 250px;
    position: fixed;
    background: #ffffff;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    z-index: 1;
    display: flex;
  }

  .sidebar-content-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    &.player-present {
      height: calc(100% - 90px);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fefefe;
    position: relative;

    &.player-present {
      margin-bottom: 90px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
