"use strict";

import axios from "../axios-aws-client";
import Store from "../../Store";
import AWS from "aws-sdk";

export default {
  forceTokenValidation() {
    //To be used when first loading the app
    //util/inject-auth-header.js will inject the token. if the token is expired,
    // they will automatically be redirected to login by util/intercept-401.js
    let token = localStorage.getItem("awsToken");
    Store.commit("auth/update_aws_token", token);
    return axios.get("/auth-ping");
  },
  logOut() {
    return new Promise((resolve, reject) => {
      if (AWS.config.credentials) {
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
      }
      Store.commit("auth/update_aws_token", "");
      resolve();
    });

  }
};