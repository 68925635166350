<template>
  <div id="dropzone-preview" class="file-row">
    <div class="dz-preview dz-file-preview" v-if="queuedTrack.file">
      <div class="main-details-wrapper">
        <div class="dz-details">
          <div class="dz-filename">{{queuedTrack.file.name}}<span data-dz-name></span></div>
          <div class="dz-size">{{(queuedTrack.file.size / (1024 * 1024)).toFixed(2)}} MB<span data-dz-size></span></div>
        </div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-controls">
          <div>
            <button class="action-button" @click="removeFile" :disabled="queuedTrack.status==='success'">
              cancel
            </button>
          </div>
          <div>
            <button class="action-button" v-show="queuedTrack.status==='added' &&  !queuedTrack.file.uploadUrl"
                    disabled>
              <span class="action-button-icon-wrapper">
                <img class="action-button-icon"
                     :src="getProcessingStatusIcon()"/> &nbsp;
              </span>
              preparing...
            </button>
            <button class="action-button" v-show="queuedTrack.status==='added' && queuedTrack.file.uploadUrl"
                    @click="startUpload">
              pending...
            </button>
            <button class="action-button" v-show="queuedTrack.status==='uploading'"
                    disabled>
              <span class="action-button-icon-wrapper">
                <img class="action-button-icon"
                     :src="getProcessingStatusIcon()"/> &nbsp;
              </span>
              uploading...
            </button>

            <button class="action-button" v-show="queuedTrack.status==='success'"
                    disabled>
              <span class="action-button-icon-wrapper">
                <img class="action-button-icon"
                     :src="getCheckmarkIcon()"/> &nbsp;
              </span>
              uploaded
            </button>
          </div>
        </div>
      </div>
      <div class="progress-bar-wrapper">
        <progress-bar :progress="queuedTrack.upload.progress"></progress-bar>

      </div>
    </div>
  </div>
</template>

<script>
  "use strict";
  import ProgressBar from "./Progress-Bar.vue";
  import ProcessingIcon from "./icons/processing.svg";
  import CheckMark from "./icons/tick.svg";

  export default {
    name: "DropzonePreview",
    components: {ProgressBar},
    props: {
      queuedTrack: {
        type: [File, Object],
        required: true
      },
      dropzone: {
        required: true
      }
    },
    methods: {
      getProcessingStatusIcon() {
        return ProcessingIcon;
      },
      getCheckmarkIcon() {
        return CheckMark;
      },
      startUpload() {
        this.dropzone.processFile(this.queuedTrack.file);
      },
      removeFile() {
        this.dropzone.removeFile(this.queuedTrack.file);
      }
    },

  };
</script>

<style lang="scss">

  .main-details-wrapper {
    display: flex;
    flex: 1;
  }

  .action-button {
    display: flex;
    align-items: center;

    .action-button-icon-wrapper {
      display: flex;
      align-items: center;
      .action-button-icon {
        width: 14px;
      }
    }

  }

  .dz-preview {
    padding: 15px;
    display: flex;
    flex: 1;
    min-height: 100px;
    flex-direction: column;

    .progress-bar-wrapper {
      display: flex;
      align-items: flex-end;
    }

    .dz-details {
      display: flex;
      flex: 1;
      flex-direction: column;
      .dz-filename {
        flex: 1;

      }
      .dz-size {
        opacity: 0.8;
        flex: 1;
      }
    }

    .dz-upload {
      display: flex;
      height: 100%;
      background-color: #1D7874;
      border-radius: 10px;
      width: 0;
    }

    .dz-remove {
      position: absolute;
      z-index: 30;
      color: white;
      margin-left: 15px;
      padding: 10px;
      top: inherit;
      bottom: 15px;
      border: 2px white solid;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 800;
      letter-spacing: 1.1px;
      opacity: 0;
    }

    &:hover {
      .dz-remove {
        opacity: 1;
      }
    }

    .dz-success-mark, .dz-error-mark {
      flex: 1;
      i {
        color: white !important;
        font-size: 1px !important;
      }
    }
    .dz-error-message {
      top: calc(50% + 25px);
      left: calc(50% - 35px);
    }
  }

  .dz-controls {
    display: flex;
    align-items: right;
  }

  .status-icon {
    width: 50px;
    height: 50px;
  }

</style>