"use strict";

import howler from "howler";

const Howl = howler.Howl;
import Store from "../../Store";
import Bus from "../../Bus";


let _track;

export default {
  playing() {
    return _track && _track.playing();
  },
  toggleLoop: _toggleLoop,
  pause: _pauseTrack,
  play: play,
  seekByPercentage: _seekByPercentage,
  stopAndCleanup
};


function stopAndCleanup() {
  if (_track) {
    _track.stop();
  }
}

function _loadTrack(trackToLoad) {
  if (_track) {
    _track.unload();
  }

  _track = new Howl({
    src: [trackToLoad.mp3],
    html5: true
  });
  initTrackEvents();

}

function _toggleLoop() {
  let newLoopState = !_track.loop();
  _track.loop(newLoopState);
  Store.commit("player/update_isLooping", newLoopState);
}

function _seekByPercentage(percentage) {
  let seekTime = (percentage / 100) * _track.duration();
  _track.seek(seekTime);
}

function play(trackToPlay) {
  if (_track && decodeURIComponent(trackToPlay.mp3) === decodeURIComponent(_track._sounds[0]._node.src)) {

    if (_track.playing()) {
      return;
    }
    _track.play();
    Store.dispatch("playTrack", trackToPlay);

  } else {
    Store.commit("player/update_is_buffering", true);
    _loadTrack(trackToPlay);
    _track.play();
    Store.dispatch("playTrack", trackToPlay);
  }


  //mobile notification controls
  if ('mediaSession' in navigator) {
    setupMediaSession(trackToPlay);
  }

}

function setupMediaSession(trackToPlay) {
  navigator.mediaSession.metadata = new MediaMetadata({
    title: trackToPlay.title,
    artist: 'dittypal',
    artwork: [
      {src: 'https://dummyimage.com/96x96', sizes: '96x96', type: 'image/png'},
      {src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png'},
      {src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png'},
      {src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png'},
      {src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png'},
      {src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png'},
    ]
  });

  navigator.mediaSession.setActionHandler('play', function () {
    play(trackToPlay);
    navigator.mediaSession.playbackState = "playing";
  });
  navigator.mediaSession.setActionHandler('pause', function () {
    _pauseTrack();
    navigator.mediaSession.playbackState = "paused";
  });
  // navigator.mediaSession.setActionHandler('seekbackward', function () { /* Code excerpted. */
  // });
  // navigator.mediaSession.setActionHandler('seekforward', function () { /* Code excerpted. */
  // });
  navigator.mediaSession.setActionHandler('nexttrack', function () {
    if (Store.getters["nextTrack"]) {
      play(Store.getters["nextTrack"]);
      navigator.mediaSession.playbackState = "playing";
    }
  });
  navigator.mediaSession.setActionHandler('previoustrack', function () {
    if (Store.getters["previousTrack"]) {
      play(Store.getters["previousTrack"]);
    }
  });
}

function _pauseTrack() {
  _track.pause();
  Store.dispatch("player/pauseTrack");
}

function _updateBufferProgressPercentage() {
  if (_track._sounds[0]._node.buffered.length > 0) {
    let progress = _track._sounds[0]._node.buffered.end(0) / _track.duration() * 100;
    Store.commit("player/update_buffer_progress_percentage", progress);
  }
}

function _updateTrackProgressPercentage() {

  if (!_track.playing()) {
    return;
  }

  let progress = _track.seek() / _track.duration() * 100;
  Store.commit("player/update_play_progress_percentage", progress);
}

function _updateProgress() {

  if (!_track.playing()) {
    return;
  }

  let progress = _track.seek();
  Store.commit("player/update_play_progress", progress);
}


function _checkBuffering() {
  let readyState = _track._sounds[0]._node.readyState;
  if (_track && (readyState === 1 || readyState === 0)) {

    if (!Store.getters["player/isBuffering"]) {
      Store.commit("player/update_is_buffering", true);
    }
  } else {
    if (Store.getters["player/isBuffering"]) {
      Store.commit("player/update_is_buffering", false);
    }
  }
}

function initTrackEvents() {
  _track.on("load", function () {
    Store.commit("player/update_duration", _track.duration());
    setInterval(_updateTrackProgressPercentage, 1000);
    setInterval(_updateBufferProgressPercentage, 1000);
    setInterval(_updateProgress, 500);
    setInterval(_checkBuffering, 500);
  });

  _track.on("end", function () {
    Store.commit("player/update_is_playing", false);
    Store.commit("player/update_play_progress", _track.duration());
    Bus.$emit("track-end");
  });
}