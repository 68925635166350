<template>
  <div class="playerControls">
    <div class="mainControls">
      <div class="mainControlWrapper">
        <img v-show="!isLooping" @click="toggleLoop" class="mainControl" src="./icons-svg/loop.svg">
        <img v-show="isLooping" @click="toggleLoop" class="mainControl" src="./icons-svg/loop-on.svg">
      </div>
      <div class="mainControlWrapper">
        <img :v-html="getPlayButtonBackground()" @click=previous
             :class="['mainControl', {'disabled-control':noTrackToPlay || noPreviousTrack }]"
             src="./icons-svg/previous.svg"
        />
      </div>
      <div class="mainControlWrapper">
        <img @click="playOrPause"
             :class="['mainControl', 'playPause', {'disabled-control':noTrackToPlay} ]"
             :src="getPlayButtonBackground()"/>
      </div>
      <div class="mainControlWrapper">
        <img @click=next :class="['mainControl',{'disabled-control':noTrackToPlay || noNextTrack}]"
             src="./icons-svg/skip.svg"/>
      </div>
      <div class="mainControlWrapper">
        <img v-show="!isShuffling" @click="toggleShuffle" class="mainControl" src="./icons-svg/shuffle.svg">
        <img v-show="isShuffling" @click="toggleShuffle" class="mainControl" src="./icons-svg/shuffle-on.svg">
      </div>
    </div>
  </div>
</template>
<script>
import audioManager from "./audio-manager";
import playIcon from "./icons-svg/play-3.svg";
import bufferingIcon from "./icons-svg/loading.svg";
import pauseIcon from "./icons-svg/pause-3.svg";

export default {
  name: "PlayerControlsMobile",
  props: {
    track: {
      type: Object
    },
    toggleLoop: {
      type: Function
    },
    toggleShuffle: {
      type: Function
    },
    isLooping: {
      type: Boolean
    },
    isShuffling: {
      type: Boolean
    }
  },

  computed: {
    isPlaying: function() {
      return this.$store.getters["player/isPlaying"];
    },
    isBuffering: function() {
      return this.$store.getters["player/isBuffering"];
    },
    noTrackToPlay() {
      return !this.$store.getters["player/track"];
    },
    noNextTrack() {
      return this.$store.getters["nextTrack"] === null;
    },
    nextTrack() {
      return this.$store.getters["nextTrack"];
    },
    previousTrack() {
      return this.$store.getters["previousTrack"];
    },
    noPreviousTrack() {
      return this.$store.getters["previousTrack"] === null;
    }
  },
  created() {
    document.body.addEventListener("keyup", this.applyContrlActionFromKeyPress);
    setInterval(() => {}, 5000);
  },
  methods: {
    getPlayButtonBackground() {
      if (this.isBuffering) {
        return bufferingIcon;
      } else {
        if (this.isPlaying) {
          return pauseIcon;
        } else {
          return playIcon;
        }
      }
    },

    applyContrlActionFromKeyPress(e) {
      let pressedKey = e.which;
      switch (pressedKey) {
        case 32: //space
          e.preventDefault();
          this.playOrPause();
          return false;
      }
    },

    playOrPause(e) {
      if (this.noTrackToPlay) {
        return;
      }

      if (!this.isPlaying) {
        audioManager.play(this.$store.getters["player/track"]);
      } else {
        audioManager.pause();
      }
    },

    next() {
      if (this.noNextTrack) {
        return;
      }
      audioManager.play(this.nextTrack);
    },
    previous() {
      if (this.noPreviousTrack) {
        return;
      }
      audioManager.play(this.previousTrack);
    }
  }
};
</script>

<style scoped lang="scss">
.playerControls {
  display: flex;
  flex: 1;
  justify-content: center;
}

.mainControls {
  display: flex;
  align-items: center;
  flex: 1;
}

.mainControlWrapper {
  flex: 1;
  /*padding: 0 20px;*/
  position: relative;
  text-align: center;
}

.mainControl {
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.mainControl:hover {
  transform: scale(1.15);
}

.playPause {
  width: 48px;
  height: 48px;
  background-color: black;
  border-radius: 100%;
}

.playPause:hover {
  transform: scale(1.05);
}

.disabled-control {
  opacity: 0.3;
  cursor: default;

  &:hover {
    transform: none;
  }
}

.inner-circle {
  border: 3px solid #ecebfa;
  border-top-color: #746ebb;
  border-radius: 100%;
  animation: turning_cw 5s infinite;
  position: relative;
  opacity: 0.7;
}

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>