"use strict";

import getCogintoUserPool from "../get-cognito-user-pool";
import addMinutes from "date-fns/add_minutes";
import {
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import store from "../../Store";
import Bus from "../../Bus";
import debug from "../../debugger";

const config = process.env.config;
AWS.config.region = config.aws.region;
let s3 = null;
let lambda = null;
let apiGateway = null;


function initCredentials(token) {
  let _token = token || localStorage.getItem("awsToken");

  if (!_token) {
    return new Promise((resolve, reject) => {
      reject("No valid user token");
    });
  }

  const params = {
    RoleArn: config.aws.cognito.assumedRoleArn,
    IdentityPoolId: config.aws.cognito.identityPoolId,
    Logins: {}
  };
  params.Logins[config.aws.cognito.providerName] = _token;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
  return new Promise((resolve, reject) => {


    AWS.config.credentials.get(function (err) {
      if (err) {
        console.log("failed creds");
        reject(err);
      } else {
        store.commit("auth/update_aws_token", _token);
        store.commit("auth/update_refresh_tokens_at", addMinutes(new Date(), 30));
        //only initialize services (s3, λ and API gateway) after getting credentials
        s3 = new AWS.S3();
        lambda = new AWS.Lambda();
        apiGateway = new AWS.APIGateway();
        resolve();


        setTimeout(refreshTokens, store.getters["auth/refreshTokensAt"]);
        debug("AWS credentials will be refreshed at " + store.getters["auth/refreshTokensAt"]);
      }
    });
  });
}

function login(email, password) {
  let userData = {
    Username: email,
    Pool: getCogintoUserPool()
  };
  let cognitoUser = new CognitoUser(userData);

  let authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password
  });

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {onSuccess: resolve, onFailure: reject});
  }).then(onAuthenticateUserSuccess)
    .catch(onAuthenticateUserFailure);


  function onAuthenticateUserSuccess(result) {
    return initCredentials(result.getIdToken().getJwtToken());
  }

  function onAuthenticateUserFailure(err) {

    return new Promise((resolve, reject) => {
      reject(getErrorMessage(err));
    });

    function getErrorMessage(err) {
      let errorCodeMap = {
        "User is not confirmed.": "Your account has not been verified, please check your email for verification instructions",
        "User does not exist.": "Incorrect username or password",
        "Password attempts exceeded": "Password attempts exceeded. Please try again after 5 minutes"
      };
      return errorCodeMap[err.message] || err.message;
    }
  }

}

function refreshTokens() {
  let userPool = getCogintoUserPool();
  let cognitoUser = userPool.getCurrentUser();

  let cachedSession = "";
  cognitoUser.getSession((err, session) => {
    if (err) {
      throw err;
    }
    cachedSession = session;
  });


  cognitoUser.refreshSession(cachedSession.refreshToken, (err, session) => {
    if (err) {
      throw err;
    }
    const tokens = getTokens(session);

    const params = {
      RoleArn: config.aws.cognito.assumedRoleArn,
      IdentityPoolId: config.aws.cognito.identityPoolId,
      Logins: {}
    };
    params.Logins[config.aws.cognito.providerName] = tokens.idToken;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);

    AWS.config.credentials.get(function (err) {
      if (err) {
        throw err;
      }
      store.commit("auth/update_aws_token", tokens.idToken);
      store.commit("auth/update_refresh_tokens_at", addMinutes(new Date(), 30));

      //only initialize services (s3, λ and API gateway) after getting credentials
      s3 = new AWS.S3();
      lambda = new AWS.Lambda();
      apiGateway = new AWS.APIGateway();
      Bus.$emit("creds-refreshed");
    });
  });

  function getTokens(session) {
    return {
      accessToken: session.getAccessToken().getJwtToken(),
      idToken: session.getIdToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken()
    };
  }

}

export {
  initCredentials,
  login,
  s3,
  lambda

};

export default AWS;


