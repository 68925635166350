"use strict";

import axios from "axios";
import store from "../Store";
import router from "../router";

let instance = axios.create({
  baseURL: "https://rgw5jsqcq9.execute-api.eu-central-1.amazonaws.com/development/"
});

instance.interceptors.request.use(function (config) {
  config.headers.Authorization = store.getters["auth/awsToken"];
  return config;
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  if (error.response && error.response.status === 401) {
    console.log("Going to Login");
    router.push({name: "Login"});
  }
  return Promise.reject(error);
});


export default instance;