<template>
  <div class="tracklist">
    <transition-group tag="div" name="list">
      <div :key="track.title" v-for="track in tracks">
        <tracklist-track :active="activeTrack && (activeTrack.title===track.title)"
                         :track="track">
        </tracklist-track>
      </div>
      </transition-group>
      <upload-your-first-track v-show="usingPlaceholderTracks" />
  
  </div>
</template>
<script>
import TracklistTrack from "./Trackilst-track";
import UploadYourFirstTrack from "../upload-your-first-track/upload-your-first-track";

export default {
  components: { TracklistTrack, UploadYourFirstTrack },
  name: "TrackList",
  computed: {
    usingPlaceholderTracks() {
      return this.$store.getters.usingPlaceholderTracks;
    }
  },
  props: {
    tracks: {
      required: true,
      type: Array
    },
    activeTrack: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
.tracklist {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.list-move {
  transition: transform 0.6s;
}

.list-leave-active {
  position: absolute;
  transition: all 0.6s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateX(300px);
}
</style>