import { render, staticRenderFns } from "./upload-your-first-track.vue?vue&type=template&id=09b6e6f2&scoped=true&"
import script from "./upload-your-first-track.vue?vue&type=script&lang=js&"
export * from "./upload-your-first-track.vue?vue&type=script&lang=js&"
import style0 from "./upload-your-first-track.vue?vue&type=style&index=0&id=09b6e6f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09b6e6f2",
  null
  
)

component.options.__file = "upload-your-first-track.vue"
export default component.exports