<template>
  <div>
    <div :class="{'mobile-hide':!isMobile, 'mobile-show':isMobile}" class="player-wrapper">
      <div class="player ">
        <div class="current-track-wrapper mobile-hide">
          <current-track :track="track"></current-track>
        </div>
        <div class="seek-and-controls">
          <seek-bar></seek-bar>
          <player-controls :toggleLoop="toggleLoop" :toggleShuffle="toggleShuffle" :isLooping="isLooping"
                           :isShuffling="isShuffling" />
        </div>
        <div class="player-right-placeholder mobile-hide">

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CurrentTrack from "./Current-Track.vue";
import PlayerControls from "./Player-Controls.vue";
import SeekBar from "./Seek-Bar";

export default {
  name: "Player",
  props: {
    track: {
      type: Object
    }
  },
  components: {
    SeekBar,
    CurrentTrack,
    PlayerControls
  },
  mounted() {},
  watch: {
    track: function(newTrack) {
      if (!newTrack) {
        return;
      }
      this.$store.commit("player/update_track", newTrack);
    }
  },
  methods: {
    toggleLoop() {
      this.$store.commit("update_is_looping", !this.$store.getters.isLooping);
    },
    toggleShuffle() {
      this.$store.dispatch("toggleShuffle");
    }
  },
  computed: {
    isLooping() {
      return this.$store.getters.isLooping;
    },
    isShuffling() {
      return this.$store.getters.isShuffling;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    }
  }
};
</script>

<style scoped lang="scss">
@import "~style/colors";
@import "~style/variables";
@import "./responsive";

.player-wrapper {
  display: flex;
  flex: 1;
  width: 100%;
  height: $player-height;
  background-color: $element-background;
  box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.75);
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.current-track-wrapper {
  flex: 1;
}

.seek-and-controls {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.player {
  align-items: stretch;
  flex: 1;
  display: flex;
}

.player-right-placeholder {
  flex: 1;
}
</style>