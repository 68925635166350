<template>
  <div :class=$style.wrapper>
    <div :class=$style.playProgressWrapper>
      <div :style="`width:${playProgressPercentage}%`" :class="[$style.progressBar, $style.playProgressPercentage]">
      </div>
      <div :style="`left:calc(${playProgressPercentage}% - 5px)`" :class="$style.playProgressCircle"></div>
    </div>
    <div :style="`width:${bufferProgressPercentage}%`" :class="[$style.progressBar, $style.bufferProgressPercentage]">
    </div>

    <div :class="[$style.progressBar, $style.fullProgress]"></div>
    <div @click="seek" :class="[$style.progressBar, $style.clickToSeek]">
    </div>

    <div :class="$style.timeIndicatorsWrapper">
      <div :class="[$style.timeIndicator]">{{formattedProgress.minutes}}:{{formattedProgress.seconds}}</div>
      <div :class="$style.timeIndicator">{{formattedDuration.minutes}}:{{formattedDuration.seconds}}</div>
    </div>


  </div>
</template>

<script>
  import audioManager from "./audio-manager";

  export default {
    name: "SeekBar",
    mounted(){
      console.log(this.$style)
    },
    props: {},
    computed: {
      playProgressPercentage() {
        return this.$store.getters["player/playProgressPercentage"];

      },
      bufferProgressPercentage() {
        return this.$store.getters["player/bufferProgressPercentage"];

      },
      formattedProgress() {
        return this.formatSecondsToMinutesAndSeconds(this.$store.getters["player/progress"]);

      },
      formattedDuration() {
        return this.formatSecondsToMinutesAndSeconds(this.$store.getters["player/duration"]);
      }
    },
    methods: {
      seek(e) {
        let self = this;
        getSeekedPercentage();

        function getSeekedPercentage() {
          let progressBarWidth = self.$el.clientWidth;
          let seekedPercentage = e.offsetX / progressBarWidth * 100;
          audioManager.seekByPercentage(seekedPercentage);
          self.$store.commit("player/update_play_progress_percentage", seekedPercentage);
        }
      },

      formatSecondsToMinutesAndSeconds(timeInSeconds) {


        let minutes = Math.floor(timeInSeconds / 60);
        let seconds = (timeInSeconds % 60).toFixed(0);

        return {
          minutes: padWithZeros(minutes),
          seconds: padWithZeros(seconds)
        };

        function padWithZeros(number) {
          let zerosPad = "00";
          let numberAsString = String(number);
          return zerosPad.substring(0, zerosPad.length - numberAsString.length) + numberAsString;
        }

      }
    }
  };
</script>

<style lang="scss" module>

  @import "~style/colors";
  .wrapper {
    display: flex;
    position: relative;
    height: 2px;

  }

  .progressBar {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.3s ease-out;
  }

  .playProgressPercentage {
    display: flex;
    flex-direction: column;
    background-color: $primary-color;
    opacity: 1;

  }

  .playProgressCircle {
    $size: 14px;

    display: flex;
    flex-shrink: 0;
    width: $size;
    height: $size;
    border-radius: 100%;
    background: $primary-color;
    position: absolute;
    top: -$size/2;
    box-shadow: 1px 2px 7px #333;
    transition: left 0.3s ease-out;
  }

  .bufferProgressPercentage {
    display: flex;
    flex-direction: column;
    background-color: #333;
    opacity: 0.4;
    z-index: 1;
  }

  .fullProgress {
    display: flex;
    background-color: #666;
    width: 100%;
    opacity: 0.2;
    z-index: auto;
  }

  .timeIndicatorsWrapper {
    display: flex;
    width: 100%;
    padding: 16px 10px;
    justify-content: space-between;
  }

  .timeIndicator {
    font-size: 10px;
  }

  .playProgressWrapper {
    display: flex;
    z-index: 2;
  }

  .clickToSeek {
    $seekable-bar-size: 12px;
    /*need something clickable to go on top of all other progress bars so that we can seek anywhere on the progress bar*/
    cursor: pointer;
    z-index: 3; //On top of everything else
    width: 100%;
    height: $seekable-bar-size;
    top: -$seekable-bar-size/2;
    opacity: 0;
  }

</style>