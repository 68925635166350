<template>
  <div class="progress-bar-wrapper">

    <div class="progress-bar" :style="`width:${progress}%`">
    </div>
  </div>
</template>

<script>
  export default {
    name: "progressBar",
    props: {
      progress: {
        type: Number,
        required: true
      }
    },
    mounted(){

    }
  }
</script>

<style lang="scss" scoped>
  .progress-bar {
    display: flex;
    height: 100%;
    background-color: #1D7874;
    border-radius: 10px;
  }

  .progress-bar-wrapper {
    height: 5px;
    display: flex;
    flex: 1;
    box-shadow: inset 0 0 16px -4px rgba(0, 0, 0, 0.75);
    margin: 10px 0;
  }

</style>