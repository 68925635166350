"use strict";


const Store = {
  namespaced: true,
  state: {
    track: null,
    isPlaying: false,
    playProgressPercentage: 0,
    bufferProgressPercentage: 0,
    duration: 0,
    progress: 0,
    isLooping: true,
    isBuffering: false
  },
  mutations: {
    update_is_buffering(state, isBuffering) {
      state.isBuffering = isBuffering;
    },

    update_play_progress(state, progress) {
      state.progress = progress;
    },
    update_isLooping(state, isLooping) {
      state.isLooping = isLooping;
    },
    update_duration(state, duration) {
      state.duration = duration;
    },
    update_play_progress_percentage(state, progress) {
      state.playProgressPercentage = progress;
    },

    update_buffer_progress_percentage(state, progress) {
      state.bufferProgressPercentage = progress;
    },

    update_track(state, newTrack) {
      state.track = newTrack;
    },

    update_is_playing(state, isPlaying) {
      state.isPlaying = isPlaying;
    },
    setTrackPlayerInstance(state, playerInstance) {
      state.trackPlayerInstance = playerInstance;
    }
  },
  actions: {
    playTrack(context, trackToPlay) {
      context.commit("update_track", trackToPlay);
      context.commit("update_is_playing", true);
    },
    pauseTrack(context) {
      context.commit("update_is_playing", false);
    }
  },
  getters: {
    track: state => state.track,
    currentPlayerState: state => state.currentPlayerState,
    isPlaying: state => state.isPlaying,
    isLooping: state => state.isLooping,
    duration: state => state.duration,
    progress: state => state.progress,
    playProgressPercentage: state => state.playProgressPercentage,
    bufferProgressPercentage: state => state.bufferProgressPercentage,
    isBuffering: state => state.isBuffering
  }
};

export default Store;

