<template>
  <div>


    <div class="auth-form-wrapper">
      <form class="auth-form" v-on:submit.prevent="submit">


        <div class="header">
          <img src="/headphones.png"/>
          <h1>
            Log in
          </h1>
        </div>


        <div class="auth-alert-wrapper">
          <div v-show="justRegistered" class="alert alert-success">
            We have sent you an email. Follow the instructions to verify your account
          </div>
        </div>


        <div class="input-group">
          <label for="email">email&nbsp;
            <small class="form-error" v-show="errors.has('email')">{{ errors.first('email') }}</small>
          </label>
          <input v-model="email" v-validate="'required|email'" placeholder="name@domain.com" class="" name="email"
                 id="email"/>
        </div>

        <div class="input-group">
          <label for="password">password&nbsp;
            <small class="form-error" v-show="errors.has('password')">{{ errors.first('password') }}</small>
          </label>
          <input v-model="password" v-validate="'required'" type="password" placeholder="password" class=""
                 name="password" id="password"/>
        </div>

        <div class="input-group">
          <input v-show="status!=='submitting'" type="submit" class="button primary" value="login"/>
          <button class="action-button" v-show="status==='submitting'" disabled>
            <span class="action-button-icon-wrapper">
              <img class="action-button-icon" :src="getSubmittingStatusIcon()"/>
              &nbsp;logging in...


            </span>
          </button>
        </div>

        <div class="alert-wrapper">
          <div v-show="loginError" class="alert">
            {{loginError}}
          </div>
        </div>

        <div class="prompt-wrapper">
          <router-link to="register">Don't have an account? Sign up</router-link>
        </div>

        <div class="prompt-wrapper">
          <contact-us></contact-us>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import submittingIcon from "./icons/submitting.svg";
import getCogintoUserPool from "../../services/get-cognito-user-pool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import AWS from "aws-sdk";
import Auth from "../../services/auth";
import { login } from "../../services/aws/aws-client";
import ContactUs from "../contact-us/contact-us.vue";
import MarketingBlurb from "../marketing-blurb.vue";
import Store from "../../Store";

export default {
  components: {
    MarketingBlurb,
    ContactUs
  },
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      status: "",
      loginError: "",
      justRegistered: false
    };
  },
  mounted() {
    this.justRegistered = this.$route.query.justRegistered;
    if (this.$store.getters["auth/isAuthenticated"]) {
      this.$router.push("/");
    }
  },
  methods: {
    getSubmittingStatusIcon() {
      return submittingIcon;
    },
    submit() {
      let self = this;
      self.$validator.validateAll().then(result => {
        if (!result) {
          return; //Validation failed
        }
        self.loginError = "";
        self.status = "submitting";
        login(self.email, self.password)
          .then(function() {
            self.$router.push("/");
          })
          .catch(function(err) {
            self.loginError = err;
            self.status = "ready";
          })
          .then(() => {
            self.status = "ready";
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./responsive.scss";
@import "../style/auth";
</style>