"use strict";

import AWS from "../services/aws/aws-client";
import {s3} from "../services/aws/aws-client";

const config = process.env.config;

export default function getSignedUrlToGetTrack(filename) {
  const params = {
    Bucket: `${config.aws.trackBucketName}/cognito/${AWS.config.credentials.identityId}`,
    Key: filename
  };
  return s3.getSignedUrl('getObject', params);
}
