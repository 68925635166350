"use strict";

import _ from "lodash";

function fileToObject(file) {
  let object = Object.assign({}, file);
  object.fileName = file.name;
  object.file = file;
  return object;
}


const Store = {
  namespaced: true,
  state: {
    uploadProgress: 0,
    queuedTracks: [],
    dropzone: null
  },
  mutations: {
    update_queued_track(state, newQueuedTrack) {
      if (newQueuedTrack.removed) {
        console.warn("file was removed before it was ready for upload", `'${newQueuedTrack.name}'`);
        return;
      }
      let trackToUpdateIndex = _.findIndex(state.queuedTracks, {fileName: newQueuedTrack.name});
      state.queuedTracks.splice(trackToUpdateIndex, 1, fileToObject(newQueuedTrack));
    },
    add_track_to_upload_queue(state, trackToQueue) {
      let trackAlreadyOnQueue = _.find(state.queuedTracks, {fileName: trackToQueue.name});
      if (trackAlreadyOnQueue) {
        console.warn("Track already in queue, not adding");
        return;
      }
      state.queuedTracks.unshift(fileToObject(trackToQueue));
    },
    remove_file_from_queue(state, fileName) {
      let trackToRemoveIndex = _.findIndex(state.queuedTracks, {fileName});
      state.queuedTracks.splice(trackToRemoveIndex, 1);
    },
    update_dropzone(state, dropzone) {
      state.dropzone = dropzone;
    }
  },
  actions: {},
  getters: {
    uploadProgress: state => state.uploadProgress,
    queuedTracks: state => state.queuedTracks,
    queuedTrack(state, trackName) {

    },
    dropzone: state => state.dropzone
  }
};

export default Store;