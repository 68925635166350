<template>
  <div class="sidebar">
    <div class="sidebar-section sidebar-image-wrapper">
      <img src="https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal-logo.png"/>
    </div>


    <div class="sidebar-section">

      <router-link exact-active-class="is-active" to="/" class="sidebar-item">play</router-link>

      <router-link exact-active-class="is-active" to="upload" class="sidebar-item">upload</router-link>


    </div>
    <div class="sidebar-section">
      <div @click="logout" class="sidebar-item">
        <span>log out</span>
      </div>
      <div class="sidebar-item smaller">
        <contact-us></contact-us>
      </div>
    </div>
  </div>
</template>

<script>
  import Auth from "../../services/auth";
  import audioManager from "../player/audio-manager";
  import ContactUs from "../contact-us/contact-us.vue";

  export default {
    components: {ContactUs},
    name: "sidebar",

    methods: {
      logout() {
        const self = this;

        Auth.logOut().then(function () {
          self.$router.push("login");
          setImmediate(() => {
            location.reload();//TODO quick cheap hack
          });
        });

      }
    }

  };
</script>

<style lang="scss" scoped>
  @import "~style/variables";
  @import "responsive";

  .sidebar {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 20px;
    flex: 1;
  }

  .sidebar-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
  }

  .sidebar-item {
    padding: 15px 0;
    cursor: pointer;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: #eeeeee;
    }


    &.is-active {
      color: #ffffff;
      background-color: #1D7874;
    }

  }

  .sidebar-image-wrapper {
    img {
      width: 100%;
    }
    flex: 0;
  }


</style>