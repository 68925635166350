<template>
  <div class="marketing-blurb">
    <h1>
      ALL your favourite tunes, anytime.
    </h1>

    <h3>
      dittypal is your simple, lightweight personal music companion.
      <br/>
      Upload your library and enjoy it from anywhere!
    </h3>
  </div>
</template>

<script>
  export default {
    name: "marketingBlurb"
  };
</script>

<style lang="scss">

  .marketing-blurb {
    text-align: center;
    color: #00B4C7;
    h1, h2, h3 {
      font-weight: 100;
    }

  }
</style>