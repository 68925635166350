<template>

  <div :key="track.title" class="track-wrapper" :class="{'active':active}">
    <div @click="activateTrack(track)" class="track">
      <div class="cover-wrapper">
        <img class="cover" :src="track && track.cover"/>
      </div>
      <div class="track-details">
        <div class="title">
          {{track && track.title}}
        </div>
        <div class="artist">
          {{track && track.artist && track.artist.title}}
        </div>
      </div>
    </div>

    <div v-show="!track._isPlaceHolder" class="action-button-wrapper">
      <button @click="deleteTrack" class="action-button">
        remove from library
      </button>
    </div>
  </div>
</template>

<script>
import audioManager from "../player/audio-manager";
import removeTrackFromDb from "../../services/remove-track-from-db";
import { s3 } from "../../services/aws/aws-client";
import AWS from "../../services/aws/aws-client";
import generateSignedUrl from "../../services/generate-signed-url-to-get-track";

const config = process.env.config;

export default {
  name: "Tracklist-track",

  props: {
    track: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean
    }
  },

  methods: {
    deleteTrack() {
      const self = this;
      self.$store.dispatch("removeTrack", self.track.title);
      removeTrackFromDb(self.track.title + self.track.extension, function(err) {
        if (err) {
          console.error(err);
        }

        const params = {
          Bucket: `${config.aws.trackBucketName}/cognito/${
            AWS.config.credentials.identityId
          }`,
          Key: self.track.title + self.track.extension
        };

        s3.deleteObject(params, function(err) {
          if (err) {
            throw err;
          }
        });
      });
    },
    activateTrack(track) {
      if (!track._isPlaceHolder) {
        track.mp3 = generateSignedUrl(track.fullTitle);
      }
      audioManager.play(track);
    }
  }
};
</script>

<style scoped lang="scss">
@import "~style/colors";

.action-button-wrapper {
  display: flex;
  padding: 0 25px;
}

.action-button {
  font-size: 10px;
  padding: 4px;
}

.track-wrapper {
  display: flex;
  flex-direction: row;
  height: 50px;
  box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  background-color: #ffffff;
  font-size: 12px;
  align-items: center;
  &:hover {
    background-color: #eeeeee;
    /*color: #fff;*/
  }
  &.active {
    background-color: $primary-color;
    color: #fff;
    .action-button {
      border: 1px solid #fff;
      background-color: $primary-color;
      color: #fff;
      &:hover {
        border: 1px solid #fff;
        background-color: #fff;
        color: $primary-color;
      }
    }
  }
}

.track {
  flex: 1;
  font-size: 14px;
  height: 50px;
  display: flex;
  cursor: pointer;
}

.cover-wrapper {
  display: flex;
  width: 50px;
  padding-right: 15px;
}

.cover {
  width: 100%;
}

.title {
}

.artist {
  opacity: 0.75;
}

.track-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.track-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>