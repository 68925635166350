"use strict";

import _ from "lodash";

const Store = {
  namespaced: true,
  state: {
    credentials: null
  },
  mutations: {
    update_credentials(state, credentials) {
      state.credentials = credentials;
      localStorage.setItem("awsCredentials", JSON.stringify(_.pick(credentials, [
        'accessKeyId',
        'secretAccessKey',
        'sessionToken'
      ])));
    }
  },
  actions: {},
  getters: {
    credentials: state => state.credentials
  }
};

export default Store;