<template>

  <div>
    <div class="auth-form-wrapper">
      <form class="auth-form" v-on:submit.prevent="submit">

        <div class="header">
          <img src="/headphones.png"/>

          <h1>
            Sign up
          </h1>

        </div>


        <div class="input-group">
          <label for="email">email&nbsp;
            <small class="form-error" v-show="errors.has('email')">{{ errors.first('email') }}</small>
          </label>
          <input v-model="email" v-validate="'required|email'" placeholder="name@domain.com" class="" name="email"
                 id="email"/>
        </div>

        <div class="input-group">
          <label for="password">password&nbsp;
            <small class="form-error" v-show="errors.has('password')">{{ errors.first('password') }}</small>
          </label>
          <input v-model="password" v-validate="'required|min:6'" type="password" placeholder="password" class=""
                 name="password" id="password"/>
        </div>
        <div class="input-group">
          <label for="password-confirmation">confirm password&nbsp;
            <small class="form-error" v-show="errors.has('password confirmation')">
              {{ errors.first('password confirmation') }}
            </small>
          </label>
          <input v-validate="'required|password-match'" type="password" placeholder="confirm password" class=""
                 name="password confirmation"
                 id="password-confirmation"/>
        </div>

        <div class="input-group">
          <input v-show="status==='ready'" type="submit" class="button primary" value="sign up"/>
          <button class="action-button" v-show="status==='submitting' || status==='loggingIn'" disabled>
            <span class="action-button-icon-wrapper">
              <img class="action-button-icon" :src="getSubmittingStatusIcon()"/>
              &nbsp;{{status === 'submitting'? 'signing you up...': 'prepping your account...' }}
            </span>
          </button>
        </div>

        <div class="alert-wrapper">
          <div v-show="registrationError" class="alert">
            {{registrationError}}
          </div>
        </div>

        <div class="prompt-wrapper">
          <router-link to="login">already have an account? Log in</router-link>
        </div>

        <div class="prompt-wrapper">
          <contact-us></contact-us>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
  import {Validator} from "vee-validate";
  import submittingIcon from "./icons/submitting.svg";
  import getCogintoUserPool from "../../services/get-cognito-user-pool";
  import ContactUs from "../contact-us/contact-us.vue";
  import MarketingBlurb from "../marketing-blurb.vue"
  import {login} from '../../services/aws/aws-client';

  let cognitoUserPool = getCogintoUserPool();

  export default {
    components: {
      MarketingBlurb,
      ContactUs
    },
    name: "Register",
    data() {
      return {
        email: "",
        password: "",
        passwordConfirmation: "",
        status: "ready",
        registrationError: ""
      };
    },
    created() {
      let self = this;
      self.nonMatchingPasswordsValidator = {
        getMessage(field, args) {
          return "Must be the same as the password above";
        },
        validate(value, args) {
          return value === self.password;
        }
      };
      Validator.extend("password-match", self.nonMatchingPasswordsValidator);
    },
    mounted() {
      if (this.$store.getters["auth/isAuthenticated"]) {
        this.$router.replace("/");
      }
    },
    methods: {
      getSubmittingStatusIcon() {
        return submittingIcon;
      },
      submit() {
        let self = this;
        self.$validator.validateAll().then(result => {
          if (!result) {
            return; //Validation failed
          }
          self.status = "submitting";
          cognitoUserPool.signUp(self.email, self.password, [], null, function (
            err,
            result
          ) {

            self.registrationError = "";
            if (err) {
              self.registrationError = err.message;
              self.status = "ready";
              return;
            }
            self.status = "loggingIn";
            login(self.email, self.password)
              .then(() => {

                self.status = "ready";
                self.$router.push("/");
              })
          });
        })
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "../style/auth";
</style>