<template>
  <a class="smaller" href="mailto:dittypal@gmail.com">queries or feedback? get in touch
    dittypal@gmail.com
  </a>

</template>

<script>
  export default {
    name: "contactUs"
  };
</script>

<style lang="scss" scoped>

</style>