<template>
      <router-link to="/upload" tag="div" key="upload-cta" class="upload-first-track-cta-wrapper">
        <div class="upload-first-track-cta">
          <img src="/add-circular-button.svg"/>
          Choose a tune to play above or click here to upload your own
        </div>
      </router-link>
</template>


<script>
export default {
  name: "UploadYourFirstTrack"
};
</script>


<style lang="scss" scoped>
.upload-first-track-cta-wrapper {
  display: flex;
  height: 75px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  color: #1d7874;
  background-color: #ffffff;
  font-size: 12px;
  align-items: center;
  margin-top: 20px;
  flex: 1;

  .upload-first-track-cta {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    font-size: 14px;
    height: 50px;
    display: flex;
    cursor: pointer;
    height: 100%;

    img {
      width: 100px;
    }
  }
}
</style>
