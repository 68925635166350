"use strict";

import Vue from "vue";
import Vuex from "vuex";
import playerStore from "../components/player/Store";
import uploadStore from "../components/upload-track/Store";
import authStore from "../services/auth/Store";
import awsStore from "../services/aws/Store";
import _ from "lodash";


Vue.use(Vuex);

const Store = new Vuex.Store({
  state: {
    availableTracks: [],
    usingPlaceholderTracks: false,
    activeTrack: null,
    isLooping: false,
    isShuffling: false,
    playOrder: null,
    activeTrackIndexInPlayOrder: null,
    isMobile: false
  },

  mutations: {
    remove_track_from_available_tracks(state, titleOfTrackToRemove) {
      if (!titleOfTrackToRemove) {
        console.error(titleOfTrackToRemove + " cannot be empty");
        return;
      }


      let trackIndexToDelete = _.findIndex(state.availableTracks, {title: titleOfTrackToRemove});
      if (trackIndexToDelete === -1) { //track not found in array;
        return;
      }
      console.log(trackIndexToDelete);
      state.availableTracks.splice(trackIndexToDelete, 1);
    },
    update_available_tracks(state, availableTracks) {
      state.availableTracks = availableTracks;
    },
    update_is_looping(state, isLooping) {
      state.isLooping = isLooping;
    },
    update_is_shuffling(state, isShuffling) {
      state.isShuffling = isShuffling;

    },
    update_active_track(state, activeTrack) {
      state.activeTrack = activeTrack;
    },
    update_play_order(state, playOrder) {
      state.playOrder = playOrder;
    },
    current_playing_index_in_play_order(state, activeTrackIndex) {
      state.activeTrackIndexInPlayOrder = activeTrackIndex;
    },
    update_is_mobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    update_using_placeholder_tracks(state, usingPlaceholderTracks) {
      state.usingPlaceholderTracks = usingPlaceholderTracks;
    }
  },
  actions: {
    removeTrack(context, titleOfTrackToRemove) {
      if (!titleOfTrackToRemove) {
        console.error(titleOfTrackToRemove + " cannot be empty");
        return;
      }
      context.commit("remove_track_from_available_tracks", titleOfTrackToRemove);
      context.dispatch("initializeTracks", JSON.parse(JSON.stringify(context.state.availableTracks)));
    },
    playTrack(context, trackToPlay) {
      context.commit("update_active_track", trackToPlay);
      context.dispatch("getActiveTrackIndexInPlayOrder");
      context.dispatch("player/playTrack", trackToPlay);
    },

    initializeTracks(context, tracks) {
      assignIndexToEachTrack();

      const usePlaceHolderTracks = tracks.length === 0;

      if (usePlaceHolderTracks) {
        tracks = getPlaceHolderTracks();

      }

      context.commit("update_using_placeholder_tracks", usePlaceHolderTracks);

      context.commit("update_available_tracks", tracks);
      context.dispatch("computePlayOrder");

      function getPlaceHolderTracks() {
        return [
          {
            "title": "dittypal - What does it mean.mp3",
            "cover": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal-logo-no-title.png",
            "mp3": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal+-+What+does+it+mean.mp3",
            "dateCreated": "2018-03-28T13:49:35.761Z",
            _isPlaceHolder: true
          },
          {
            "title": "dittypal - lonely IaM.mp3",
            "cover": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal-logo-no-title.png",
            "mp3": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal+-+lonely+IaM.mp3",
            "dateCreated": "2018-03-28T13:49:35.761Z",
            _isPlaceHolder: true
          },
          {
            "title": "dittypal - It's coming.mp3",
            "cover": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal-logo-no-title.png",
            "mp3": "https://s3.eu-central-1.amazonaws.com/content.tuneco/dittypal+-+It's+coming.mp3",
            "dateCreated": "2018-03-28T13:49:35.761Z",
            _isPlaceHolder: true
          },
        ];
      }


      function assignIndexToEachTrack() {
        tracks = tracks.map((t, index) => {
          t.index = index;

          const fullTitle = t.fullTitle || t.title;
          t.fullTitle = fullTitle;
          t.title = fullTitle.substring(0, fullTitle.lastIndexOf('.'));
          t.extension = fullTitle.substring(fullTitle.lastIndexOf('.'));
          return t;
        });
      }
    },

    computePlayOrder(context) {
      let playOrder = context.state.availableTracks.map(t => t.index);
      if (context.state.isShuffling) {
        playOrder = _.shuffle(playOrder);
        if (context.state.activeTrack) {
          //setActiveTrackAsFirstInPlayOrder();
          let activeTrackIndex = _.findIndex(context.state.playOrder, (originalTrackIndex) => context.state.activeTrack.index === originalTrackIndex);
          let trackThatWasFirstIndex = playOrder[0];
          playOrder[0] = activeTrackIndex;
          playOrder[activeTrackIndex] = trackThatWasFirstIndex;
        }

      }
      context.commit("update_play_order", playOrder);
    },

    toggleShuffle(context) {
      context.commit("update_is_shuffling", !context.state.isShuffling);
      context.dispatch("computePlayOrder");
      context.dispatch("getActiveTrackIndexInPlayOrder");
    },
    getActiveTrackIndexInPlayOrder(context) {
      if (!context.state.activeTrack) {
        return null;
      }
      let activeTrackIndex = _.findIndex(context.state.playOrder, (originalTrackIndex) => context.state.activeTrack.index === originalTrackIndex);

      context.commit("current_playing_index_in_play_order", activeTrackIndex);
    }
  },

  getters: {
    availableTracks: state => state.availableTracks,
    activeTrack: state => state.activeTrack,
    nextTrack: state => {


      if (!state.activeTrack) {
        return null;
      }

      let indexToPlay;

      if (state.activeTrackIndexInPlayOrder === (state.playOrder.length - 1)) {
        if (state.isLooping) {
          indexToPlay = 0;
        }
      } else {
        indexToPlay = state.playOrder[state.activeTrackIndexInPlayOrder + 1];
      }

      return state.availableTracks[indexToPlay] || null;

    },
    previousTrack: state => {
      if (!state.activeTrack) {
        return null;
      }
      if (state.activeTrack.index === 0) {
        return null;
      }
      let indexToPlay = state.playOrder[state.activeTrackIndexInPlayOrder - 1];
      return state.availableTracks[indexToPlay] || null;
    },
    isLooping: state => state.isLooping,
    isShuffling: state => state.isShuffling,
    isMobile: state => state.isMobile,
    usingPlaceholderTracks: state => state.usingPlaceholderTracks

  },
  modules: {
    player: playerStore,
    upload: uploadStore,
    auth: authStore,
    aws: awsStore
  }
});


export default Store;