<template>
  <div class="currentTrack">
    <div class="coverArtWrapper">
      <img class="coverArt " :src="track && track.cover"/>
      <!--<div class="coverArtHighlight"></div>-->
    </div>
    <div class="trackDetails">
      <div class="title">
        {{track && track.title}}
      </div>
      <div class="artistName">
        {{track && track.artist && track.artist.title}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CurrentTrack",
    props: {
      track: {
        type: Object
      }
    }
  };
</script>

<style scoped lang="scss">
  .currentTrack {
    display: flex;
    min-height: 80px;
    padding: 0;
    flex: 1;
  }

  .coverArt {

    width: 100%;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.75);

  }

  .coverArtHighlight {
    width: 100%;
    left: 0;
    top: 0;
    background: transparent url(https://logikgatemusic.com/images/album-cover-highlight.png) no-repeat 0 0 scroll;
    height: 100%;

  }

  .trackDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
  }

  .title {

  }

  .coverArtWrapper {
    position: relative;
    width: 90px;
    height: 90px;
    padding-right: 15px;
  }

  .artistName {
    font-size: 0.9em;
    opacity: 0.75;
  }

</style>