"use strict";

import AWS, {s3, lambda} from "./aws/aws-client";

const config = process.env.config;

function saveTrackToDatabase(file) {
  console.log("saving track", file);
  let params = {
    FunctionName: 'saveTrackDataToDb',
    // InvocationType: "DryRun",
    Payload: JSON.stringify({
      title: file.name,
      mp3Location: `https://${s3.endpoint.hostname}/${config.aws.trackBucketName}/cognito/${AWS.config.credentials.identityId}/${file.name}`
    })
  };

  lambda.invoke(params, function (error, data) {
    if (error) {
      console.error(error);
    } else {
      console.log(data);
    }
  });

}


export default saveTrackToDatabase;