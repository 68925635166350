"use strict";


const Store = {
  namespaced: true,
  state: {
    awsToken: null,
    isAuthenticated: false,
    refreshTokensAt: null
  },
  mutations: {
    update_aws_token(state, token) {
      state.awsToken = token;
      localStorage.setItem("awsToken", token);
    },
    update_refresh_tokens_at(state, refreshTokensAt) {
      state.refreshTokensAt = refreshTokensAt;
      localStorage.setItem("refreshTokensAt", refreshTokensAt);
    }
  },
  actions: {},
  getters: {
    awsToken: state => state.awsToken,
    refreshTokensAt: state => state.refreshTokensAt,
    isAuthenticated: state => {
      return Boolean(state.awsToken);
    }
  }
};

export default Store;