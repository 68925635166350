<template>
  <div  class="loading-image-wrapper">
    <img src="../../img/app_loader.svg"/>
  </div>
</template>

<script>
  export default {
    name: "Loading"
  };
</script>

<style lang="scss">

  .loading-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    img {
      width: 120px;
    }
  }
</style>