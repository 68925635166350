"use strict";


import axios from "axios";

const musicApiBase = "https://rgw5jsqcq9.execute-api.eu-central-1.amazonaws.com/development/getTracksForUser";


export default axios.create({
  baseURL: musicApiBase,
  headers: {}
});
