"use strict";

import {lambda} from "./aws/aws-client";

const noop = () => {
};

export default function removeTrackFromDatabase(title, callback = noop) {
  console.log("removing track", title);
  let params = {
    FunctionName: 'remove-track-from-db',
    // InvocationType: "DryRun",
    Payload: JSON.stringify({
      title: title,
    })
  };

  lambda.invoke(params, function (error, data) {
    if (error) {
      return callback(error);
    } else {
      callback(null, data);
    }
  });

}

