import Vue from "vue";
import Router from "vue-router";
import VeeValidate from 'vee-validate';
import UploadTrack from "../components/upload-track/Upload-Track.vue";
import Register from "../components/register/Register.vue";
import Login from "../components/login/Login.vue";
import Home from "../components/home/Home.vue";

import Store from "../Store";


Vue.use(Router);
Vue.use(VeeValidate);


const router = new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/upload",
      name: "Upload",
      component: UploadTrack
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  // if (to.name !== "Login" && to.name !== "Register") {
  //   if (!Store.getters["auth/isAuthenticated"]) {
  //     router.push("/login");
  //   }
  // }
  next();


});


export default router;